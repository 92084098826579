import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maplibre"
export default class extends Controller {
  static values = {
    spec: String,
    element: String
  }

  connect() {
    if (this.specValue > "") {
      const el = document.getElementById(this.elementValue);

      // Initialise the map
      const map = new maplibregl.Map({
        container: el,
        // style: "/maps/style.json",
        style: "https://osm.iceflower.ai/styles/positron/style.json",
        center: [-74, 40.7],
        zoom: 10
      });

      // Add the navigation control
      map.addControl(new maplibregl.NavigationControl());
    }
  }
}

