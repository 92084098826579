import { Controller } from "@hotwired/stimulus"

import { venn, lossFunction, normalizeSolution, scaleSolution } from "../layout"

// Connects to data-controller="vega"
export default class extends Controller {
  static values = {
    spec: String,
    element: String,
    spark: Array,
    bullet: Array,
    spectrum: Array,
    venn: String
  }

  connect() {
    const div = document.createElement('div');

    if (this.specValue > "") {
      const el = document.getElementById(this.elementValue);
      el?.firstElementChild?.classList.add('blur-lg')
      vegaEmbed(div, this.specValue, { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.sparkValue.length > 0) {
      const el = this.element;
      console.log(this.sparkValue);
      vegaEmbed(div, this.sparkline(this.sparkValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.bulletValue.length > 0) {
      const el = this.element;
      console.log(this.bulletValue);
      vegaEmbed(div, this.bullet(this.bulletValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.spectrumValue.length > 0) {
      const el = this.element;
      console.log(this.spectrumValue);
      vegaEmbed(div, this.spectrum(this.spectrumValue), { actions: false }).then(function (result) {
        el.replaceChildren(div);
        el.value = result.view // Access the Vega view instance (https://vega.github.io/vega/docs/api/view/)
      }).catch(console.error);
    }

    if (this.vennValue.length > 0) {
      const el = this.element;
      console.log(this.vennValue);
      this.vennchart();
    }
  }

  vennchart() {
    var data = [ 
        {sets: ['A'], size: 12},
        {sets: ['B'], size: 12},
        {sets: ['A','B'], size: 2}];

    // handle 0-sized sets by removing from input
    var toremove = {};
    data.forEach(function(datum) {
        if ((datum.size == 0) && datum.sets.length == 1) {
            toremove[datum.sets[0]] = 1;
        }
    });
    data = data.filter(function(datum) {
        return !datum.sets.some(function(set) { return set in toremove; });
    });

    var circles = {};

    if (data.length > 0) {
        var solution = venn(data, {lossFunction: lossFunction});

        solution = normalizeSolution(solution, null, null);

        circles = scaleSolution(solution, 600, 300, 50);
        console.log(circles);
    }

    // work with a list instead of a dictionary
    var result = [], setid;
    for (setid in solution) {
        if (solution.hasOwnProperty(setid)) {
            var previous = solution[setid];
            result.push({x: previous.x,
                          y: previous.y,
                          radius: previous.radius,
                          setid: setid});
        }
    }

    return spec = {
      $schema: "https://vega.github.io/schema/vega/v5.json",
      width: 300,
      height: 200,
      autosize: "none",

      signals: [
        {
          name: "selected", value: nil,
          on: [
            { events: "symbol:mouseover", update: "datum" },
            { events: "symbol:mouseout", update: "null" }
          ]
        },
        { name: "bandwidth", value: 5 },
        { name: "resolve", value: "shared" },
        { name: "merchant", value: nil }
      ],


      data: [
        {
          name: "states",
          url: "us-10m.json",
          format: { type: "topojson", feature: "states" },
          transform: [
            {
              type: "geopath",
              projection: "projection"
            }
          ]
        },
        {
          name: "zips",
          values: csv_string,
          format: { type: "csv" },
          transform: [
            {
              type: "geopoint",
              projection: "projection",
              fields: [ "lon", "lat" ]
            }
          ]
        },
        {
          name: "density",
          source: "zips",
          transform: [
            {
              type: "filter",
              expr: "datum.x > 0 && datum.y > 0 && (!merchant || datum.merchant == merchant)"
            },
            {
              type: "kde2d",
              size: [ { signal: "width" }, { signal: "height" } ],
              weight: "customers",
              x: { expr: "datum.x" },
              y: { expr: "datum.y" },
              bandwidth: { signal: "[bandwidth, bandwidth]" },
              counts: { signal: false },
              cellSize: 2
            }
          ]
        },
        {
          name: "populationdensity",
          source: "zips",
          transform: [
            {
              type: "filter",
              expr: "datum.x > 0 && datum.y > 0"
            },
            {
              type: "kde2d",
              size: [ { signal: "width" }, { signal: "height" } ],
              weight: "total",
              x: { expr: "datum.x" },
              y: { expr: "datum.y" },
              bandwidth: { signal: "[bandwidth, bandwidth]" },
              counts: { signal: false },
              cellSize: 2
            }
          ]
        },
        {
          name: "minmax",
          source: "populationdensity",
          transform: [
            {
              type: "flatten",
              fields: [ "grid.values" ],
              as: [ "value" ]
            },
            {
              type: "aggregate",
              fields: [ "value", "value" ],
              ops: [ "min", "max" ],
              as: [ "min_value", "max_value" ]
            }
          ]
        },
        {
          name: "heatmap",
          source: "density",
          transform: [
            {
              type: "formula",
              expr: "datum.grid.width",
              as: "width"
            },
            {
              type: "formula",
              expr: "datum.grid.height",
              as: "height"
            },
            {
              type: "heatmap",
              field: "grid",
              color: { expr: "scale('color', (datum.$value / datum.$max) / (normalized ? (0.1+data('populationdensity')[0].grid.values[(datum.$y+8) * datum.width + datum.$x+8] / data('minmax')[0].max_value) : 0.5))" },
              opacity: { expr: "2*(datum.$value / datum.$max) / (normalized ? (0.1+data('populationdensity')[0].grid.values[(datum.$y+8) * datum.width + datum.$x+8] / data('minmax')[0].max_value) : 0.5)" }
            }
          ]
        }
      ],

      scales: [
        {
          "name": "color",
          "type": "linear",
          "domain": [ 0, 2 ],
          "range": { "scheme": "spectral" }
        }
      ],

      marks: [
        {
          type: "image",
          from: { data: "heatmap" },
          encode: {
            update: {
              x: { value: 0 },
              y: { value: 0 },
              image: { field: "image" },
              width: { signal: "width" },
              height: { signal: "height" },
              aspect: { value: false }
            }
          }
        },
        {
          type: "path",
          from: { data: "states" },
          encode: {
            enter: {
              strokeWidth: { value: "1" },
              stroke: { value: "#888" },
              fill: { value: "#ffffff00" },
              path: { field: "path" }
            }
          }
        },

        {
          type: "symbol",
          from: { data: "zips" },
          encode: {
            enter: {
              shape: "circle",
              size: { value: 1 },
              fill: { value: "#00000010" }
            },
            update: {
              x: { field: "x" },
              y: { field: "y" }
            }
          }
        }
      ]
    }
  }
 
  sparkline(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      data: {
        values: [{ q: values }],
        name: "dataset"
      },

      transform: [
        {
          flatten: ["q"]
        },
        {
          window: [{
            op: "rank",
            as: "index"
          }]
        }
      ],

      height: 20,
      width: 100,
      padding: 0,

      mark: {
        type: "area",
        interpolate: "monotone",
        line: { color: "steelblue", strokeWidth: 1, interpolate: "monotone" },
        fill: {
          x1: 1,
          y1: 1,
          x2: 1,
          y2: 0,
          gradient: "linear",
          stops: [
            {
              offset: 0,
              color: "#B0C4DE20"
            },
            {
              offset: 1,
              color: "#B0C4DE"
            }
          ]
        }
      },
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          axis: {
            title: null,
            orient: "top",
            domain: false,
            ticks: false,
            labels: false,
            grid: false
          }
        },
        y: {
          field: "q",
          type: "quantitative",
          axis: {
            title: null,
            domain: false,
            labels: false,
            ticks: false,
            grid: false
          }
        }
      },

      config: {
        view: { stroke: "", fill: "", fillOpacity: 0 },
        header: {
          title: null
        }
      }
    }
  }

  bullet(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      data: {
        values: [{
          lower: values[0],
          q1: values[1],
          median: values[2],
          q3: values[3],
          upper: values[4],
          average: values[5],
          b1: 200,
          b2: 400,
          b3: 600
        }]
      },

      height: 20,
      width: 100,
      padding: 0,

      layer: [
        {
          mark: { type: "bar", color: "#f0f0f0", clip: true },
          encoding: { x: { field: "b3", type: "quantitative", scale: { domain: [0, 600] }, title: null } }
        },
        {
          mark: { type: "bar", color: "#e8e8e8" },
          encoding: { x: { field: "b2", type: "quantitative" } }
        },
        {
          mark: { type: "bar", color: "#e0e0e0" },
          encoding: { x: { field: "b1", type: "quantitative" } }
        },
        {
          mark: { type: "bar", size: 8 },
          encoding: {
            x: { field: "lower", type: "quantitative" },
            x2: { field: "upper" },
            color: { value: "lightsteelblue" }
          }
        },
        {
          mark: { type: "bar", size: 12 },
          encoding: {
            x: { field: "q1", type: "quantitative" },
            x2: { field: "q3" },
            color: { value: "steelblue" }
          }
        },
        {
          mark: { type: "tick", color: "white", size: 14 },
          encoding: {
            x: {
              field: "median", type: "quantitative",
              axis: {
                domain: false,
                ticks: false,
                labels: false,
                grid: false
              }
            }
          }
        },
        {
          mark: { type: "tick", color: "black", size: 20, thickness: 2 },
          encoding: { x: { field: "average", type: "quantitative" } }
        }
      ],
      config: { view: { stroke: "", fill: "" } }
    }
  }

  spectrum(values) {
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",

      height: 15,
      width: 100,
      padding: 0,

      data: {
        values: { f: values }
      },
      transform: [
        { flatten: ["f"] },
        { window: [{ op: "rank", as: "index" }] },
        { calculate: "datum.index - 1", as: "zero_based_index" }
      ],
      mark: "rect",
      encoding: {
        x: {
          field: "index",
          type: "quantitative",
          title: null,
          axis: null,
          scale: {domain: [0, 30]}
        },
        x2: { field: "zero_based_index" },
        color: {
          field: "f",
          type: "quantitative",
          title: null,
          legend: null
        }
      },
      config: {
        view: { stroke: "transparent", fill: "", fillOpacity: 0 }
      }
    }
  }
}

